import './App.css';
import logo from "./media/Goena.png";

function App() {
  console.log('version 2.0.0');
  return (
    <div className="App">
      <header className="App-header">
       <img alt='imagelogo' style={{maxWidth:'200px',maxHeight:'100px'}} src={logo} />
        <p>
      Our website is temporarily under maintenance, <br/>
please contact support@goena.id for more information.
        </p>
        <p>
          Thank you
        </p>
      
      </header>
    </div>
  );
}

export default App;
